<template>
  <div class="mb-4">
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text v-if="!isEditingPOS">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-menu
                  ref="date_menu_start"
                  v-model="date_menu_start"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected.startDate"
                      v-bind:label="$t('start_date')"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selected.startDate"
                    no-title
                    @input="date_menu_start = false"
                    :first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    :reactive="reactive_start"
                    @change="onChangeDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-menu
                  ref="date_menu_end"
                  v-model="date_menu_end"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected.endDate"
                      v-bind:label="$t('end_date')"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selected.endDate"
                    no-title
                    @input="date_menu_end = false"
                    :first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    :reactive="reactive_end"
                    @change="onChangeDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="selected.brandId"
                  v-bind:label="$t('brands.brand')"
                  item-text="name"
                  item-value="id"
                  :items="filtered_brands"
                  hide-no-data
                  filled
                  required
                  :rules="requiredRules"
                  @change="onChangeBrandProduct('BRAND', selected.brandId)"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.productId"
                  v-bind:label="$t('Product')"
                  item-text="name"
                  item-value="id"
                  :items="filtered_products"
                  hide-no-data
                  filled
                  required
                  :rules="requiredRules"
                  @change="onChangeBrandProduct('PRODUCT', selected.productId)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.surveyId"
                  v-bind:label="$t('survey')"
                  item-text="name"
                  item-value="id"
                  :items="filtered_surveys"
                  hide-no-data
                  filled
                  required
                  :rules="requiredRules"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <h6>{{ $t("Message") }}</h6>
                <vue-editor
                  v-model="selected.messageContents"
                  :editorOptions="vue2EditorOptionsWithEmojis"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else-if="isEditingPOS">
            <messagePosTable
              :importExcelTemplatePathName="importExcelTemplatePathName"
              :posData="selected"
              :apiurl_downloadexcelposes="
                `salesforce/guidance/productalert/downloadexcelposes/${this.selected.id}`
              "
              :apiurl_uploadexcel="
                `salesforce/guidance/productalert/uploadexcelIdposperbrand/${this.selected.id}`
              "
              :getDataFromApi="getDataFromApi"
              :tableData="tableData"
              :createSelectedModel="createSelectedModel"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="onSaveClick(selected)"
              :loading="isSaving"
              v-if="!isEditingPOS"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>{{
        `${this.$t("Product")} ${this.$t("Alert")}`
      }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mr-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
            <v-btn color="warning" dark class="mr-2" @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="table-productalert"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import messagePosTable from "@/components/messagePosTable";
import { VueEditor, Quill } from "vue2-editor";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji,
  },
  true
);

export default {
  name: "MessageProductAlert",
  components: { VueEditor, messagePosTable },
  data: function() {
    return {
      isDisappearWhenReadStatusList: [],
      tableData: [],
      totaltableData: 0,

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      filterable_col_list: [
        "startDate",
        "endDate",
        "brandId",
        "productId",
        "surveyId",
        "messageContents",
      ],
      filterCriteria: {
        startDate: {},
        endDate: {},
        brandId: {},
        productId: {},
        surveyId: {},
        messageContents: {},
      },
      filterlist_fetch_url: "salesforce/guidance/productalert/getfilterlist",
      showEmpty: true,
      isSaving: false,

      /********** form-validation options **********/
      valid: true,
      requiredRules: [(v) => !!v || "Required"],
      requiredRulesArray: [(v) => (!!v && v.length > 0) || "Required"],
      selected: null,
      editedIndex: -1,
      showEdit: false,
      date_menu_start: false,
      date_menu_end: false,
      reactive_start: false,
      reactive_end: false,
      selectableBrands: [],
      selectableProducts: [],
      selectableSurveys: [],
      filtered_brands: [],
      filtered_products: [],
      filtered_surveys: [],
      importExcelTemplatePathName: null,
      isEditingPOS: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    col_id() {
      return {
        key: "id",
        title: "ID",
        field: "id",
        align: "left",
        width: 70,
        sortBy: "ASC",
        fixed: "left",
      };
    },
    col_startDate() {
      return {
        key: "startDate",
        title: this.$t("start_date"),
        field: "startDate",
        align: "left",
        width: 170,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["startDate"]["from"]}
                  value={this.filterCriteria["startDate"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["startDate"]["to"]}
                  value={this.filterCriteria["startDate"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "startDate")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_endDate() {
      return {
        key: "endDate",
        title: this.$t("end_date"),
        field: "endDate",
        align: "left",
        width: 170,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["endDate"]["from"]}
                  value={this.filterCriteria["endDate"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["endDate"]["to"]}
                  value={this.filterCriteria["endDate"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "endDate")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_brand() {
      return {
        key: "brandId",
        title: this.$t("brands.brand"),
        field: "brandId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.brand ? row.brand.name : ""}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brandId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brandId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brandId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brandId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_product() {
      return {
        key: "productId",
        title: this.$t("Product"),
        field: "productId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.product.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["productId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`productId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["productId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "productId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_survey() {
      return {
        key: "surveyId",
        title: this.$t("survey"),
        field: "surveyId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.survey.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["surveyId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`surveyId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["surveyId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "surveyId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_messageContents() {
      return {
        key: "messageContents",
        title: `${this.$t("Message")}`,
        field: "messageContents",
        align: "left",
        width: 250,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div
              class="message_contents"
              domPropsInnerHTML={row.messageContents}
            ></div>
          );
        },
      };
    },
    col_pos() {
      return {
        key: "pos",
        title: `POS`,
        field: "pos",
        align: "left",
        width: 80,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                color="blue darken-1"
                text
                on-click={() => this.onItemEdit(row, true)}
              >
                <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
              </v-btn>
            </div>
          );
        },
      };
    },
    col_edit() {
      return {
        key: "actions",
        title: this.$t("edititem"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
              <v-icon
                medium
                color="error"
                on-click={() => this.onItemRemove(row.id)}
              >
                mdi-delete
              </v-icon>
            </div>
          );
        },
      };
    },
    columns() {
      return [
        this.col_id,
        this.col_startDate,
        this.col_endDate,
        this.col_brand,
        this.col_product,
        this.col_survey,
        this.col_messageContents,
        this.col_pos,
        this.col_edit,
      ];
    },
  },
  methods: {
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#table-productalert"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value,
          });
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#table-productalert .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      try {
        let query_params_string = this.getQueryParams();
        let fetch_url = "salesforce/guidance/productalert";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        this.showEmpty = true;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        }
        this.totaltableData = resp.total;
        this.selectableBrands = resp.selectableBrands;
        this.selectableProducts = resp.selectableProducts;
        this.selectableSurveys = resp.selectableSurveys;
        this.filtered_brands = resp.selectableBrands;
        this.filtered_products = resp.selectableProducts;
        this.filtered_surveys = resp.selectableSurveys;
        this.importExcelTemplatePathName = resp.importExcelTemplatePathName;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "salesforce/guidance/productalert/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "data.xlsx");
      this.close();
    },

    async onChangeDates() {
      if (this.selected.startDate > this.selected.endDate) {
        this.selected.endDate = this.selected.startDate;
      }
    },
    closeEditDialog() {
      this.isEditingPOS = false;
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async onItemEdit(item, isEditingPOS) {
      this.isEditingPOS = isEditingPOS;
      this.selected = await this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    async createSelectedModel(item) {
      console.log(item);
      let model = {
        id: _.get(item, "id"),
        startDate: _.get(item, "startDate"),
        endDate: _.get(item, "endDate"),
        brand: _.get(item, "brand"),
        brandId: _.get(item, "brandId"),
        product: _.get(item, "product"),
        productId: _.get(item, "productId"),
        survey: _.get(item, "survey"),
        surveyId: _.get(item, "surveyId"),
        messageContents: _.get(item, "messageContents"),
        pos: _.get(item, "pos"),
      };
      if (!model.id) {
        this.filtered_brands = this.selectableBrands;
        this.filtered_products = this.selectableProducts;
        this.filtered_surveys = this.selectableSurveys;
      }
      if (model.pos) {
        model.pos = model.pos.map((el) => {
          return {
            ...el,
            status: el.message_product_alert_pos?.status
              ? "active"
              : "inactive",
          };
        });
      }
      console.log(model);
      return model;
    },
    async onSaveClick(item) {
      console.log(item);
      if (this.$refs.form.validate()) {
        let body = _.omit(item, ["product", "survey"]);
        let status = null;
        this.isSaving = true;
        if (body.id) {
          status = await ApiService.put(
            `salesforce/guidance/productalert/${body.id}`,
            body
          );
        } else {
          status = await ApiService.post(
            `salesforce/guidance/productalert`,
            body
          );
        }
        if (status) {
          logInfo(this.$t("Success"));
          this.getDataFromApi();
        } else {
          logInfo(this.$t("Failed"));
        }
        this.isSaving = false;
        this.showEdit = false;
      }
    },
    onItemRemove(item_id) {
      this.deleteItemId = item_id;
      if (this.deleteItemId > 0 && window.confirm(this.$t("confirmdelete"))) {
        ApiService.delete(
          `salesforce/guidance/productalert/${this.deleteItemId}`
        ).then(() => {
          logInfo("eliminada!");
          this.getDataFromApi();
        });
      }
    },
    onChangeBrandProduct(changedField, changedValue) {
      if (changedField === "BRAND") {
        this.filtered_products = this.selectableProducts.filter(
          (el) => el.brandId == changedValue
        );
        this.filtered_surveys = this.selectableSurveys.filter(
          (el) => el.brandId == changedValue
        );
        this.selected.productId = null;
        this.selected.surveyId = null;
      } else {
        const foundProduct = this.selectableProducts.find(
          (el) => el.id == changedValue
        );
        if (foundProduct) {
          this.selected.brandId = foundProduct?.brandId;
          this.filtered_surveys = this.selectableSurveys.filter(
            (el) => el.brandId == this.selected.brandId
          );
          this.selected.surveyId = null;
        }
      }
    },
    init() {
      this.getDataFromApi();
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style></style>

<template>
  <div>
    <MessageOverAll />
    <MessageProductAlert />
  </div>
</template>

<script>
import MessageOverAll from "./MessageOverAll";
import MessageProductAlert from "./MessageProductAlert";

export default {
  name: "guidance",
  components: {
    MessageOverAll,
    MessageProductAlert,
  },
};
</script>

<style></style>
